.mat-slider {
  &.mat-slider {
    &-horizontal {

      .mat-slider {
        &-wrapper,
        &-track-wrapper,
        &-track-background,
        &-track-fill {
          height: 6px;
        }

        &-track-wrapper {
          border-radius: 4px;
        }

        // &-track-background {
        //   background-color: rgba(255, 255, 255, 0.4);
        // }
      }

    }

    .mat-slider-thumb {
      background-color: #fff;
      border: 2px solid #C1A6F0;
      width: 35px;
      border-radius: 10px;
    }
  }

  &.slider-light {
    &.mat-slider {
      &-horizontal {
        &-track-background {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

}