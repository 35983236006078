@import '../theme/variables';

.uploadcare {
  &--button,
  &--widget__button {
    border-radius: $token-button-primary-border-radius;
    min-height: $token-button-primary-height;
    font-family: "Lato";
    font-size: 16px;
    font-weight: $token-button-primary-font-weight;
    line-height: 22px;
    text-transform: uppercase;
    padding: 0 10px;

    border-color: $token-button-stroked-border-color;
    color: $token-button-stroked-text-color;
    border-width: $token-button-stroked-border-width;
    background-color: $token-button-stroked-background-color;
    text-transform: $token-button-stroked-text-transform;
    font-weight: $token-button-stroked-font-weight;

    &:hover,
    &:focus,
    &:active {
        background-color: $token-button-stroked-hover-background-color;
        border-color: $token-button-stroked-hover-border-color;
        color: $token-button-stroked-hover-text-color;
        font-weight: $token-button-primary-font-weight;
        font-family: "Lato";
    }
  }
  &--button_primary,
  &--widget__button {
    background-color: $token-color-primary-100;
    border-color: $token-color-primary-100;
    color: $token-button-primary-text-color;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover,
    &:focus,
    &:active {
      font-weight: $token-button-primary-font-weight;
      background-color: $token-color-primary-100;
      border-color: $token-color-primary-100;
      color: $token-button-primary-text-color;
      cursor: pointer;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

  }
}
