@import '../theme/variables';

.icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: center;


  &.icon-lg {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }

  &.icon-md {
    width: 26px;
    height: 26px;
  }

  &-web {
    background-image: url('/assets/icons/ic_web.svg');
  }

  &-time {
    background-image: url('/assets/icons/ic_time.svg');
  }

  &-mobile {
    background-image: url('/assets/icons/ic_mobile.svg');
  }

  &-location {
    background-image: url('/assets/icons/ic_location.svg');
  }

  &-info {
    background-image: url('/assets/icons/ic_info.svg');
  }

  &-email {
    background-image: url('/assets/icons/ic_email.svg');
  }

  &-person {
    background-image: url('/assets/icons/ic_contact_person.svg');
  }

  &-arrow-right {
    background-image: url('/assets/icons/ic_arrow_right.svg');
  }

  &-arrow-left {
    background-image: url('/assets/icons/ic_arrow-left.svg');
  }

  &-arrow-breadcrumbs {
    background-image: url('/assets/icons/ic_arrow_breadcrumbs.svg');
  }

  &-vertical-split {
    background-image: url('/assets/icons/vertical_split.svg');
  }

  &-social {
    background-image: url('/assets/icons/ic_social_links.svg');
  }

  &-amount {
    background-image: url('/assets/icons/ic_amount.svg');
  }

  &-birthday {
    background-image: url('/assets/icons/ic_birthday.svg');
  }

  &-interests {
    background-image: url('/assets/icons/ic_interests.svg');
  }

  &-locations {
    background-image: url('/assets/icons/ic_locations.svg');
  }

  &-date {
    background-image: url('/assets/icons/ic_date.svg');
  }

  &-competence {
    background-image: url('/assets/icons/ic_competence.svg');
  }

  &-category {
    background-image: url('/assets/icons/ic_category.svg');
  }

  &-assigned {
    background-image: url('/assets/icons/ic_assigned.svg');
  }

  &-description {
    background-image: url('/assets/icons/ic_description.svg');
  }

  &-download {
    background-image: url('/assets/icons/ic_dowload.svg');
  }

  &-status {
    background-image: url('/assets/icons/ic_status.svg');
  }

  &-required-certificate {
    background-image: url('/assets/icons/ic_required_certificates.svg');
  }

  &-required-certificate-done {
    background-image: url('/assets/icons/ic_required_certificates_done.svg');
  }

  &-required-organization {
    background-image: url('/assets/icons/ic_organization_required.svg');
  }

  &-hint {
    opacity: .5;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
  }

  &-accent {
    color: $token-color-accent-100 !important;
  }

}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: 'Material Icons Round';
}

.mat-expansion-indicator::after {
  color: $token-color-primary-100 !important;
}
