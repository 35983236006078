.empty-list {
  background: url('/assets/img/ic_filtering_empty.svg');
  min-height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .empty-hint {
    max-width: 300px;
    padding-top: 90px;
  }
}