@import '../theme/variables';
@import '../mixins/functions';
@import '../mixins/breakpoints';

// Alignment

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.word-break-normal {
  word-break: normal !important;
}

.word-break-all {
  word-break: break-all !important;
}

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left {
      text-align: left !important;
    }

    .text#{$infix}-right {
      text-align: right !important;
    }

    .text#{$infix}-center {
      text-align: center !important;
    }
  }
}


// Transformation

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-truncate {
  @include media-breakpoint-down(md) {
    max-width: 300px;
  }
  // It breaks mobile view
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Weight and italics

.font-weight-light {
  font-weight: $font-weight-light !important;
}

.font-weight-lighter {
  font-weight: $font-weight-lighter !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-bolder {
  font-weight: $font-weight-bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.font-size-xxxs {
  font-size: 0.8rem !important;
}

.font-size-xxs {
  font-size: 1rem !important;
}

.font-size-xs {
  font-size: 1.2rem !important;
}

.font-size-sm {
  font-size: 1.4rem !important;
}

.font-size-md {
  font-size: 1.6rem !important;
}

.font-size-semi-lg {
  font-size: 1.8rem !important;
}

.font-size-lg {
  font-size: 2rem !important;
}

.font-size-xl {
  font-size: 2.5rem !important;
}

.font-size-2xl {
  font-size: 3rem !important;
}

.font-size-3xl {
  font-size: 3.5rem !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-md {
    line-height: 1.5 !important;
}

.line-height-lg {
    line-height: 1.75 !important;
}

.line-height-xl {
    line-height: 2 !important;
}
