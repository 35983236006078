.stat-box {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  border-bottom: 4px solid #9A73DB;
  min-width: 230px;
  margin-right: 24px;

  .box-label {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
  }

  .box-text {
    font-family: Lato;
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    margin: 0;
  }

  &-item:not(:last-of-type) {
    border-right: 1px solid #CBCBCB;
  }
}