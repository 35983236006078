@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

.mat-tab-group,
.mat-tab-nav-bar {
  font-family: 'Lato' !important;

  &.sticky-controls {
    &>.mat-tab-header {
      position: sticky;
      top: 84px;
      z-index: 100;
      background-color: white;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);

      @include media-breakpoint-down(md) {
        top: 56px;
      }
    }

    .mat-tab-label-container {
      @include make-container();
      @include make-container-max-widths();
    }

    &.dashboard-controls {
      &>.mat-tab-header {
        top: 0;
      }
    }
  }

  .mat-tab-labels,
  .mat-tab-links {
    align-items: baseline;
  }

  .mat-tab-label,
  .mat-tab-link {
    opacity: 0.7;
    color: #00151A;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 0px;
    padding: 0 12px;
    overflow: hidden;

    
    &:first-of-type {
      padding-left: 0;
    }
    
    &:hover {
      opacity: 1;
      color: $token-color-primary-100;
    }

    .btn-tab {
      color: #00151A;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-active {
      color: $token-color-primary-100;
      opacity: 1;

      .btn-tab {
        color: $token-color-primary-100;
        opacity: 1;
      }

      .mat-tab-label-content {
        border-bottom: 4px solid $token-color-primary-100;
      }
    }

    &:focus,
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  .mat-tab-link.mat-tab-label {
    &-active {
      color: $token-color-primary-100;
      opacity: 1;

      span {
        border-bottom: 4px solid $token-color-primary-100;
      }
      // border-bottom: 4px solid #d93532;
    }
  }


  .mat-tab-nav-bar, .mat-tab-header, &.mat-tab-header {
    border: none;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-body-content {
    overflow: hidden;
  }
}

// .mat-tab-nav-bar.mat-tab-header {
//   border: none;
// }

.tab-controls {
  .mat-tab-label-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-tab-label:last-of-type {
    margin-left: auto;
    opacity: 1;
    min-height: 56px;

    &.mat-tab-label-active {
      .mat-tab-label-content {
        border-bottom: none;
      }
    }
  }
}
