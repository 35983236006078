@import 'src/styles/theme/variables';
@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

.mat-stepper-horizontal {
  .mat-horizontal-stepper-header-container {
    justify-content: center;
  }

  .mat-horizontal-stepper-header {
    height: 50px;
    padding: 0 10px;

    .mat-step-icon-state-number,
    .mat-step-icon-state-edit {
      display: none;
    }
  }

  .mat-stepper-horizontal-line {
    border: none;
    height: 15px;
    max-width: 60px;
    position: relative;

    &::after {
      content: '';
      left: 25px;
      top: 15%;
      position: absolute;
      height: 10px;
      border: 2px solid $token-color-primary-100;
      border-right: none;
      border-bottom: none;
      width: 10px;
      min-width: 10px;
      flex: none;
      display: block;
      transform: rotate(135deg);
    }
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-step-label {
    font-weight: bold;
    font-size: 1.6rem;

    @include media-breakpoint-down(md) {
      max-width: 110px;
    }

    .mat-step-text-label {
      font-weight: bold;
      font-size: 1.6rem;
    }

    &.mat-step-label-active {
      color: $token-color-primary-100 !important;
      .mat-step-text-label {
        color: $token-color-primary-100;
      }
    }
  }
}
