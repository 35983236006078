.mat-slide-toggle {
  &.vertical {
    height: 32px;
    font-size: 1.2rem;
    .mat-slide-toggle {
      &-label {
        display: flex;
        flex-direction: column;
      }

      &-content {
        margin-right: 8px;
      }
    }
  }
}