@use 'sass:math'; // Should always fisrt import
@import './spacing';

$font-family-sans-serif:      "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.8 !default;
$h4-font-size:                $font-size-base * 1.6 !default;
$h5-font-size:                $font-size-base * 1.2 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      math.div($spacer, 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$small-font-size:             80% !default;