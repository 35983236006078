@import './variables';

$white:   $token-color-neutral-00 !default;
$black:   $token-color-neutral-100 !default;
$purple:  $token-color-primary-100 !default;
$grey:    $token-color-neutral-30!default;
$light:   $token-color-neutral-05 !default;
$text-color: #404040 !default;
$red:     $token-color-error !default;
$green:   $token-color-success;

$primary:           $token-color-primary-100 !default;
$primary-10:        $token-color-primary-10 !default;
$primary-30:        $token-color-primary-30 !default;
$primary-50:        $token-color-primary-50 !default;
$primary-80:        $token-color-primary-80 !default;
$secondary:         $token-color-accent-100 !default;
$grey:              $token-color-neutral-30 !default;
$error:             $token-color-error !default;
$success:           $token-color-success !default;
$light:             $token-color-neutral-05 !default;
$dark:              $text-color !default;
$white:             $white !default;
$accent:            $token-color-accent-100 !default;
$accent-blue:       $token-color-accent-blue-100 !default;
$accent-green:      $token-color-accent-green-100 !default;
$accent-green-200:  $token-color-accent-green-200 !default;
$accent-green-300:  $token-color-accent-green-300 !default;


$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":           $primary,
    "primary-80":        $primary-80,
    "primary-50":        $primary-50,
    "primary-30":        $primary-30,
    "primary-10":        $primary-10,
    "secondary":         $secondary,
    "grey":              $grey,
    "light":             $light,
    "dark":              $dark,
    "white":             $white,
    "black":             $black,
    "error":             $error,
    "success":           $success,
    "accent":            $accent,
    "accent-blue":       $accent-blue,
    "accent-green":      $accent-green,
    "accent-green-200":  $accent-green-200,
    "accent-green-300":  $accent-green-300
  ),
  $theme-colors
);

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$body-bg:                   $white !default;
$body-color:                $black !default;

$text-muted:                  $gray-600 !default;
