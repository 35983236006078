// .border         { border: $border-width solid $border-color !important; }
// .border-top     { border-top: $border-width solid $border-color !important; }
// .border-right   { border-right: $border-width solid $border-color !important; }
// .border-bottom  { border-bottom: $border-width solid $border-color !important; }
// .border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

.border { border: 1px solid; }
.border-top { border-top: 1px solid; }
.border-right { border-right: 1px solid; }
.border-bottom { border-bottom: 1px solid; }
.border-left { border-left: 1px solid; }

.border-2 { border: 2px solid; }
.border-top-2 { border-top: 2px solid; }
.border-right-2 { border-right: 2px solid; }
.border-bottom-2 { border-bottom: 2px solid; }
.border-left-2 { border-left: 2px solid; }

.border-3 {  border: 3px solid; }
.border-top-3 { border-top: 3px solid; }
.border-right-3 { border-right: 3px solid; }
.border-bottom-3 { border-bottom: 3px solid; }
.border-left-3 { border-left: 3px solid; }

.border-4 {  border: 4px solid; }
.border-top-4 { border-top: 4px solid; }
.border-right-4 { border-right: 4px solid; }
.border-bottom-4 { border-bottom: 4px solid; }
.border-left-4 { border-left: 4px solid; }

.border-5 {  border: 5px solid; }
.border-top-5 { border-top: 5px solid; }
.border-right-5 { border-right: 5px solid; }
.border-bottom-5 { border-bottom: 5px solid; }
.border-left-5 { border-left: 5px solid; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-rounded {
      border-radius: $border-radius !important;
    }

    .border#{$infix}-0 {
      border-radius: 0 !important;
    }
  }
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-full {
  border-radius: 999px !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
