@import '../theme/variables';

.mat-datepicker-content,
.mat-calendar {
  .mat-calendar-body-cell-content {
    border-radius: 4px;
    box-shadow: none !important;
    // width: 100%;
    // height: 100%;
  }

  .mat-calendar-content {
    padding: 0 20px 8px;
  }

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    width: 40px;
    height: 40px;
  }

  .mat-calendar-body-begin-range,
  .mat-calendar-body-end-range,
  .mat-calendar-cell-semi-selected,
  .mat-calendar-body-selected {
    background-color: $token-color-primary-100 !important;

    .mat-calendar-body-cell-content {
      color: $token-color-neutral-00;
    }
  }

  .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
    border-radius: 4px 0 0 4px;
  }

  .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    border-radius: 0 4px 4px 0;
  }

  .mat-calendar-cell-over > .mat-calendar-body-cell-content {
    border-color: transparent;
  }

  .mat-calendar-controls {
    @include media-breakpoint-down(sm) {
      margin: 10px 0;
    }
  }
}
.timepicker {

  .timepicker__header {
    background-color: $primary !important;
  }

  .clock-face__clock-hand {
    background-color: $primary !important;

    &::after {
      background-color: $primary !important;
    }

    &::before {
      border-color: $primary !important;
    }
  }

  .clock-face__number {
    >span.active {
      background-color: $primary !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: $primary !important;
  }
}

.mat-date-range-input {
  .mat-date-range-input-start-wrapper {
    height: 24px;
    padding-right: 1px;
  }
}
