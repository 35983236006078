@import 'src/styles/theme/variables';
@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

.mat-dialog-container {
  padding: 48px;
  max-height: 98vh !important;;
  height: auto !important;
  overflow-y: scroll;
}

.full-width-dialog .mat-dialog-container {
  @include media-breakpoint-down(md) {
    max-width: calc(100vw - 32px) !important;
    padding: 20px 16px;
    margin: 0 auto;

    .mat-dialog-content {
        margin: 0;
        padding: 0;
    }
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.5 !important;
  }

  &.transparent-backdrop {
    background: transparent;
  }
}
