.tooltip {
  &-secondary {
    background: #fff !important;
    color: #000 !important;
    font-family: Lato;
    font-size: 16px !important;
    line-height: 24px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 16px !important;
    white-space: pre-line;

    &-wide {
      min-width: 400px;
    }
  }
}
