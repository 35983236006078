@charset "UTF-8";

@import "mixins/all";
@import "generic/all";
@import "theme/all";
@import "common/all";
@import "utilities/all";
@import "components/all";
@import "animations/all";

@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
