@import "src/styles/theme/spacing";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .w#{$infix}-auto {
      width: auto !important;
    }

    .h#{$infix}-auto {
      height: auto !important;
    }

    @each $unitName, $unitValue in $spacers {
      .w#{$infix}-#{$unitName} {
        width: $unitValue !important;
      }

      .max-w#{$infix}-#{$unitName} {
        max-width: $unitValue !important;
      }

      .h#{$infix}-#{$unitName} {
        height: $unitValue !important;
      }

      .max-h#{$infix}-#{$unitName} {
        max-height: $unitValue !important;
      }
    }
  }
}
