.breadcrumbs {
  color: #FFFFFF;
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  a {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 40px;
    text-decoration: none;
    font-weight: 400;

    &:visited {
      color: #FFFFFF;
    }
  }


  .breadcrumb-title,
  .breadcrumb-link {
    opacity: 0.8;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .breadcrumb-link {
    opacity: 1;
  }
}

.icon-arrow-breadcrumbs {
  vertical-align: middle;
  height: 40px !important;
}