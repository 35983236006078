.mat-basic-chip {
  height: 32px;
  max-height: 32px;
  padding: 10px 12px;
  border: 1px solid #CBCBCB;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: rgba(0,0,0, 0.87);
  border-radius: 4px;
  margin: 0 16px 12px 0;

  &:focus {
    outline: none;
  }

  .mat-chip-remove {
    color: rgba(0,0,0, 0.87);
    width: 16px;
    height: 16px;
    margin-left: 12px !important;
    cursor: pointer;
  }
}