.detail-container {
  img {
    max-width: 100%;
  }

  .hero {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px;

    &.event-hero {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    @include media-breakpoint-down(sm) {
      height: 90px;

      h2 {
        font-size: 16px;
      }
    }
    &-canceled {
      background-color: rgba(255, 255, 255, .5);
      background-blend-mode: overlay;
    }
  }


}
