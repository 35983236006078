@import '../theme/colors';

$form-text-margin-top: .25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: .3rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;
$form-check-inline-input-margin-x: .3125rem !default;

$form-grid-gutter-width: 10px !default;
$form-group-margin-bottom: 1rem !default;

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  >.col,
  >[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.mat-select-panel {
  margin-top: 46px;

  .mat-option {
    min-height: 48px;
    height: auto !important;
  }
}

mat-form-field.mat-form-field {
  &.inverted {
    .mat-form-field-outline {

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        background-color: #fff;
      }
    }
  }
  &.readonly {
    .mat-form-field-outline {

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        background-color:$gray-200;
      }
    }
  }
}

// Basic input

.input-component.ng-invalid.ng-touched {
  .input-block {
    input {
      color: $token-input-error-text-color;
    }
  }
}

.mat-error,
.mat-hint {
  font-size: 12px;
}

.mobile-controls {
  position: fixed;
  bottom: 0;
  z-index: 100;
  left: 0;
  padding-top: 16px;
  background-color: #fff;
}

.mat-snack-bar-container {
  max-width: 60vw !important;
  min-width: 40vw !important;
  margin-top: 60px !important;
}

.mat-option[aria-disabled=true].contains-mat-select-search {
  position: relative !important;
}