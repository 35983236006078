@import 'src/styles/theme/variables';
@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

.table-container {
  display: block;
  width: 100%;
  border: 1px solid #BFC4C6;
  border-radius: 4px;
}

.flex-table {
  display: flex;
  // flex-flow: row wrap;
  // border-bottom: solid 1px #BFC4C6;
  transition: 0.5s;
  // flex-wrap: wrap;


  @include media-breakpoint-down(lg) {
    margin-bottom: 16px;
    background-color: $token-color-neutral-05;
    border-radius: 4px;
    border: none !important;
    padding: 11px 16px 16px 16px;

    &.header {
      display: none;
    }

    &.toggled {
      background-color: $token-color-neutral-05 !important;
    }
  }

  &:first-of-type {
    .flex-row {
      color: #bfbfbf;
      font-size: 1.6rem;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.toggled {
    background-color: #FFFFFF;
  }
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flex-row {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  word-break: break-all;
  font-size: 1.4rem;

}

.toggle {
  background-color: #FFFFFF;
}

.row-border {
  border-bottom: solid 1px #BFC4C6;
}
