@import '../theme/variables';

.calendar-custom-header {
    .calendar-title {
        min-width: 200px;
        font-family: Lato;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
    }

    .calendar-button {
        min-width: 40px;

        .mat-icon {
            font-size: 40px;
            line-height: 40px;
            width: 40px;
            height: 40px;
            color: $token-button-primary-background-color;
        }
    }
}

.fc {
    table {
        font-family: Lato;
    }
    .fc-toolbar.fc-header-toolbar {
        display: none;
    }
    .fc-day .fc-daygrid-day-frame {
        min-height: 110px;

        @include media-breakpoint-down(md) {
            min-height: 100%;
        }
    }
    .fc-head-container.fc-widget-header {
        position: sticky;
        top: 165px;
        background-color: #fff;
        z-index: 100;

        @include media-breakpoint-down(md) {
            top: 85px;
        }
    }
    .fc-col-header-cell-cushion {
        font-weight: 400;
        font-size: 14px
    }

    .fc-h-event .fc-event-main-frame {
        display: block;
    }

    .fc-event,
    .fc-event-main,
    .fc-event-dot {
        background-color: $token-color-primary-100;
        border-color: $token-color-primary-100;
        color: #fff;
        border-radius: 4px;

        &.slot-disabled {
            background-color: $token-color-neutral-30;
            border-color: $token-color-neutral-30;
        }
        &.slot-waitinglist {
            background-color: $token-color-neutral-30 ;
            border-color: $token-color-neutral-30;
        }
    }

    .slot-waitinglist {
        & > .fc-event-main {
            background-color: $token-color-neutral-30 ;
            border-color: $token-color-neutral-30;
        }
    }

    td.fc-daygrid-day.fc-day-today {
        // background: #f5bfbf;
        background-color: #fff;
    }

    .fc-col-header-cell-cushion,
    .fc-day-header {
        font-weight: normal;
        font-size: 14px;
        padding: 5px 0;
        color: $token-color-neutral-100;
    }

    .fc-highlight {
        background-color: $token-color-primary-100;
        opacity: .8;
    }

    .fc-button-primary {
        background-color: $token-color-neutral-00;
        border-color: $token-color-primary-100;
        color: $token-color-neutral-100;
        text-transform: uppercase;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        height: 40px;
        padding: 0 20px;
        min-width: 100px;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            color: $token-color-neutral-00;
            background-color: $token-color-primary-100;
            border-color: $token-color-primary-100;
        }
    }

    .fc-button-primary:not(:disabled).fc-button-active,
    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc-button-primary:not(:disabled):active:focus {
        background-color: $token-color-primary-100;
        border-color: $token-color-primary-100;
        box-shadow: none;
        font-weight: bold;
        box-shadow: none;
    }

    .fc-prev-button,
    .fc-next-button {
        border: none;
        padding: 0 10px;
        font-size: 20px;
        min-width: 20px;
        color: $token-color-primary-100;

        &:not(:disabled) {

            &:hover,
            &:active {
                background-color: $token-color-neutral-00;
                color: $token-color-primary-100;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .fc-daygrid-event,
    .fc-time-grid-event,
    .fc-daygrid-dot-event {
        padding: 1px;
        margin: 4px 4px 0;

        max-height: 40px;
        min-height: 40px;
        white-space: inherit;
        display: block !important;
        line-height: 1.4;
        overflow: hidden;

        &:hover {
            cursor: pointer;
            background-color: $token-color-primary-100;
        }

        .fc-event-title,
        .fc-event-title-container,
        .fc-title {
            display: inline;
            color: $token-color-neutral-00;
            font-family: Lato;
            font-size: 12px;
            padding: 0 3px;
            font-weight: 300;
        }

        .fc-event-time,
        .fc-time {
            font-weight: 700;
            font-family: 'Lato';
            font-size: 12px;
            display: inline-block;
        }

        .fc-content {
            max-height: 40px;
            min-height: 40px;
            white-space: inherit;
        }

        .fc-daygrid-event-dot {
            display: none;
        }
    }

    .fc-toolbar {
        h2 {
            font-size: 20px;
            font-family: 'Lato';
            font-weight: bold;
            line-height: 24px;
            min-width: 150px;
            text-align: center;
        }

        .fc-center {
            display: flex;
            align-items: center;
        }

        .fc-right {
            .fc-button {
                margin-left: 0;
            }

            .fc-dayGridWeek-button {
                border-radius: 0;
                border-left: 0;
            }

            .fc-dayGridMonth-button {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .fc-timeGridDay-button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            justify-content: center;

            .fc-left,
            .fc-right {
                display: none;
            }
        }
    }

    .fc-daygrid-day-number {
        padding: 5px;
        font-family: 'Lato';
        font-size: 14px;
        color: $token-color-neutral-100;
    }

    .fc-scroller {
        @include media-breakpoint-down(sm) {
            height: auto !important;
        }
    }
}


// TODO: Move to separate file


.uploadcare--widget__button {
    background-color: $token-color-primary-100;
    height: 40px;
    text-transform: uppercase;
    font-family: "Lato";
    font-weight: bold;
    border-radius: 4px;
}
