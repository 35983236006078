
  .mat-form-field {
    &.mat-focused {
      textarea {
        color: rgba(0,0,0,0.87);
      }
    }

    textarea.mat-input-element {
      font-family: Lato;
      font-size: 16px;
      line-height: 19px;
      width: 100%;
      min-height: 100px;
    }
  }
