@import 'src/styles/mixins/breakpoints';

.mat-form-field.ng-invalid.ng-touched {
  input {
    color: $token-input-error-text-color;
  }
}


.mat-form-field {
  mat-label {
    color: #595959;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0.85em 0 0.5em 0;
    }

    .mat-input-element {
      caret-color: #461e89;
    }

    &.mat-focused.mat-accent{
      .mat-form-field-outline-thick {
        color: #461e89;
      }

      .mat-form-field-label.mat-accent {
        color: #461e89;
      }
    }

    .mat-form-field-prefix {
      top: 9px;
      padding-right: 2px;
    }

    .mat-form-field-wrapper {
      min-height: 56px;
      padding-bottom: 12px;
    }

    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      min-width: 50%;
      background-color: #fff;
    }

    .mat-form-field-outline-start {
      border-radius: 4px 0 0 4px;
    }

    .mat-form-field-outline-end {
      border-radius: 0 5px 5px 0;
    }

    .mat-form-field-outline {
      min-height: 54px;
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-start {
        border-width: 1px;
      }
    }

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-0.8em);
      font-size: 12px;
    }

    .mat-form-field-label {
      margin-top: -0.05em;
      top: 22px;
      font-size: 16px;
      color: #B2B2B2;
    }

    .mat-form-field-flex {
      align-items: center;
      padding: 0 8px 0 8px;
    }

    .mat-form-field-subscript-wrapper {
      position: relative;
    }

    &.transparent {
      .mat-form-field-outline-end,
      .mat-form-field-outline-start {
        background-color: transparent !important;
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline:hover {
      cursor: not-allowed;
    }
  }

  .icon-reset{
    display: none !important;
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .icon-reset{
      display: block !important;
    }
  }

  .mat-form-field-infix {
    min-height: 56px;
    border-radius: 4px;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 4px;

    .mat-icon {
      color: #666666;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field {
    width: 100%;
    

    &.mat-focused {
      .mat-form-field-ripple {
        background-color: $token-input-selected-border-color;
      }

      input {
        color: #00071A;
      }

      label {
        color: $token-input-selected-label-text-color;
      }

      .mat-form-field-wrapper {
        border-color: $token-input-selected-border-color;
      }
    }

    .mat-form-field-underline {
      background-color: $dark;
      bottom: 0px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px;
      border: 1px solid $token-input-border-color;
      border-radius: $token-input-border-radius;
    }
  }

  label {
    padding-left: 0;
    margin-top: 6px;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  input {
    height: 24px;
    color: $token-input-content-text-color;
    font-family: Lato;
    font-size: 16px;
    font-weight: $token-input-content-font-weight;
    letter-spacing: 0.15px;
    line-height: 24px;
    width: 100%;
  }
}

// Select

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled),
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-select {
  .mat-select-arrow {
    border: none;
  }
}

friskus-events-view {
  .mat-form-field-flex {
    &:focus-within {
      background-color: rgba(0, 0, 0, 0.10);
    }
  }
}

.mat-checkbox-layout {
  white-space: normal !important;

  @include media-breakpoint-down(md) {
    .mat-checkbox-inner-container {
        padding: 10px;
    }
  }
}
