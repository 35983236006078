@import 'src/styles/theme/variables';
@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

$html-font-size: 10px;

html {
  // height: 100%;
  overflow: auto;
  font-size: $html-font-size;
}

body {
  height: 100%;
  // overflow: auto;
  font-family: $font-family-base;
  font-size: 1.6rem;
  position: relative;
}

*,
::after,
::before {
  box-sizing: border-box;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

::-webkit-scrollbar {
  @include media-breakpoint-up(md) {
    -webkit-appearance: none;
    width: 7px;
  }
}

::-webkit-scrollbar-thumb {
  @include media-breakpoint-up(md) {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
