$spacer: 16px !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 6),
    10: ($spacer * 7),
    11: ($spacer * 8),
    12: ($spacer * 9),
    13: ($spacer * 10),
    14: ($spacer * 11),
    15: ($spacer * 12),
    content-small: 640px,
    content-medium: 800px,
    content-large: 1200px,
    full: 100%
  ),
  $spacers
);

$negative-spacers: negativify-map($spacers) !default;
