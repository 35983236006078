mat-radio-button {
  .mat-radio-label-content {
    line-height: 24px;
    padding-left: 16px;
  }

  .mat-radio-label {
    white-space: normal;
    align-items: flex-start;
  }
}
