@import 'src/styles/theme/variables';
@import 'src/styles/mixins/functions';
@import 'src/styles/mixins/breakpoints';

a.slogan {
  font-family: "Lato" !important;
  text-align: center;
  opacity: 0.7;
  width: 100%;
  color: #fff;
  @include media-breakpoint-down(md) {
    font-size: 20px !important;
    line-height: 25px;
  }

  font-weight: bold;
  font-size: 46px !important;
  line-height: 60px;

  &:visited {

  }
}
