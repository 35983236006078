@import '../theme/variables';

p,
.paragraph {
  color: $token-color-neutral-100;
  font-family: Lato;
  font-size: $token-size-font-medium;
  line-height: 24px;
}

small {
  font-size: $token-size-font-small
}

a {
  font-size: $token-link-font-size;
  color: $token-link-text-color;
  font-weight: $token-link-font-weight;
}

.text-label {
  // opacity: 0.5;
  color: $token-color-neutral-50;
  font-size: $token-size-font-small;
  line-height: 15px;
}

.link {
  color: $token-color-primary-100;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }
}

.badge-label {
  font-family: 'Lato';
  font-size: $token-size-font-small;
  color: $token-color-neutral-00;
  border-radius: 4px;
  padding: 3px 4px;

  &.round {
    border-radius: 4px;
    min-width: 16px;
    min-height: 16px;
    padding: 1px 3px;
  }

  &-accent {
    background-color: $token-color-accent-100;
  }

  &-success {
    background-color: $token-color-success;
  }

  &-primary {
    background-color: $token-color-primary-100;
  }

  &-primary-80 {
    background-color: $token-color-primary-80;
  }

  &-primary-50 {
    background-color: $token-color-primary-50;
  }

  &-warning {
    background-color: #FDD295;
  }

  &-danger {
    background-color: rgb(220, 53, 69);
  }

  &-neutral {
    background-color: $token-color-neutral-05;
    border: 1px solid $token-color-neutral-50;
    color: $token-color-neutral-50;
    opacity: .9;
  }

  &-popular {
    background-color: $token-color-neutral-00;
    color: $token-color-success;
    border: 1px solid $token-color-success;
    opacity: .9;

    .mat-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;
    }
  }
}
