@import '../theme/variables';

.bg-wrap {
    border-radius: 4px;
    background-color: $token-color-neutral-05;
}

.bg-wrap-primary {
    border-radius: 4px;
    background-color: $token-color-primary-05;
}

.bg-wrap-transparent {
    border-radius: 4px;
    border: 1px solid $token-color-neutral-05;
}

.bg-wrap-error {
    border-radius: 4px;
    background-color: #FFECEC;
}