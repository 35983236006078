@import '../theme/variables';
@import '../theme/_colors.scss';
@import '../mixins/functions';
@import '../mixins/breakpoints';


.mat-button-toggle-group {
  border-color: $token-button-primary-background-color !important;
  border-radius: $token-button-primary-border-radius;

  .mat-button-toggle {
    background-color: $token-button-primary-text-color;
    color: $token-color-neutral-100;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    border-color: $token-button-primary-background-color !important;

    &:hover {
      background-color: $token-button-stroked-hover-background-color;
    }

    .mat-button-toggle-button {
      padding: 0 20px;
    }

    &.mat-button-toggle-checked {
      background-color: $token-button-primary-background-color;
      border-color: $token-button-primary-background-color !important;
      color: $token-button-primary-text-color;
      font-weight: $token-button-primary-font-weight;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
    text-transform: uppercase;
    padding: 0;
  }
}