.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90{
  transform: rotate(90deg);
}

.rotate-180{
  transform: rotate(180deg);
}

.flip {
  transform: scaleX(-1);
}