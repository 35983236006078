@import '../theme/variables';
@import '../theme/_colors.scss';
@import '../mixins/functions';
@import '../mixins/breakpoints';

button {
  &:focus {
    outline: revert;
  }
}

button.mat-primary,
button.mat-accent {
  border-radius: $token-button-primary-border-radius;
  min-height: $token-button-primary-height;
  font-family: "Lato";
  font-size: 16px;
  font-weight: $token-button-primary-font-weight;
  line-height: 22px;
  text-transform: inherit;
  max-height: 100px;
  box-shadow: none !important;

  &:focus {
    outline: revert;
  }

  &.mat-button-base {
    text-transform: inherit;

    &.link {
      min-height: auto;
    }

    &.mat-fab {
      width: 68px;
      height: 68px;

      .mat-button-wrapper {
        padding: 4px 0;
      }
    }
  }

  &[disabled] {
    border-color: $token-button-primary-disabled-border-color !important;
    color: $token-button-primary-disabled-border-color !important;
  }

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.mat-raised-button.mat-primary {
  background-color: $primary;
}

.mat-stroked-button.mat-primary {
  border-color: $primary;
  color: $primary;
  border-width: $token-button-primary-border-width;
  white-space: normal;

  &:not(.mat-button-disabled) {
    border-color: $primary;
  }
}

button.mat-stroked-button.mat-accent {
  border-color: $token-button-stroked-border-color;
  color: $token-button-stroked-text-color;
  border-width: $token-button-stroked-border-width;
  white-space: normal;
  background-color: $token-button-stroked-background-color;
  text-transform: $token-button-stroked-text-transform;
  font-weight: $token-button-stroked-font-weight;

  &:hover {
    background-color: $token-button-stroked-hover-background-color;
    border-color: $token-button-stroked-hover-border-color;
    color: $token-button-stroked-hover-text-color;
  }
}

button.mat-raised-button.mat-accent {
  text-transform: $token-button-stroked-text-transform;
}

button.mat-button,
button.mat-button-base {
  &:hover {
    .mat-button-focus-overlay {
      opacity: 0;
    }

    // .mat-button-wrapper {
    //     text-decoration: underline;
    // }
  }

  &.icon {
    font-size: 1.2rem;
    line-height: 15px;
    font-weight: normal;
    white-space: normal;
    height: auto;
    max-height: 56px;
    word-break: break-word;
    hyphens: auto;
    padding: 0 2px;

    .mat-icon {
      display: block;
    }

    .mat-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-text {
        color: #666666;
      }
    }

    &:hover {
      .btn-text {
        color: $token-color-primary-100;
      }
    }
  }
}

button.mat-button-base {
  &.icon {
    .mat-icon {
      color: #000;
      opacity: 0.5;
    }

    &.no-hover {
      .mat-icon {
        opacity: 1;
      }
    }

    &:hover {
      .mat-icon {
        color: $token-color-primary-100;
        opacity: 1;
      }
    }
  }

}

button.mat-icon-button {
  @include media-breakpoint-up(sm) {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.primary-icon-button {
  .mat-icon {
    color: $token-color-neutral-50;
  }

  &:hover {
    .mat-icon {
      color: $token-color-primary-100;
    }
  }
}

button.btn-transparent {
  background: transparent;
  border: none;
}
