@import "src/styles/theme/colors";

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@each $unitName, $unitValue in $theme-colors {
  .bg-#{$unitName} {
    background-color: $unitValue !important;
  }

  .color-#{$unitName} {
    color: $unitValue !important;
  }

  .fill-#{$unitName} {
    fill: $unitValue !important;
  }
}

@each $unitName, $unitValue in $grays {
  .bg-gray-#{$unitName} {
    background-color: $unitValue !important;
  }

  .color-gray-#{$unitName} {
    color: $unitValue !important;
  }

  .fill-gray-#{$unitName} {
    fill: $unitValue !important;
  }
}
