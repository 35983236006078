@import "src/styles/theme/spacing";

$positions: static, relative, absolute, fixed, sticky;
$zindex-fixed: 10;
$zindex-fixed: 1;
$zindex-sticky: 10;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $position in $positions {
      .position#{$infix}-#{$position} {
        position: $position !important;
      }
    }

    .top#{$infix}-unset {
        top: unset;
    }
    .bottom#{$infix}-unset {
        bottom: unset;
    }
    .left#{$infix}-unset {
        left: unset;
    }
    .right#{$infix}-unset {
        right: unset;
    }

    @each $unitName, $unitValue in $spacers {
      .top#{$infix}-#{$unitName} {
        top: $unitValue;
      }
      .right#{$infix}-#{$unitName} {
        right: $unitValue;
      }
      .bottom#{$infix}-#{$unitName} {
        bottom: $unitValue;
      }
      .left#{$infix}-#{$unitName} {
        left: $unitValue;
      }
    }
  }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.sticky-top {
  @supports (position: sticky) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}

.inset-0 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} 
 
.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}
